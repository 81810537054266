import Inputmask from "inputmask";
import { selectize } from 'selectize';
import tippy from 'tippy.js';

(function () {

  // tooltips

  tippy('[data-tippy-content]', {
    arrow: false,
    placement: 'bottom'
  });

  // dropdowns

  const dropdown = document.querySelectorAll('.js-selectize')

  if (dropdown.length) {
    dropdown.forEach(el => {
      $(el).selectize()
    })
  }

  // modals

  function openModal (id) {
    const modal = document.getElementById(id)

    if (modal) {
      document.body.classList.add('block-scroll')
      modal.classList.add('active')
    }
  }

  function closeModal (target) {
    const modal = $(target).parents('.js-modal')

    if (modal) {
      document.body.classList.remove('block-scroll')
      modal.removeClass('active')
    }
  }

  const openBtn = document.querySelectorAll('.js-modal-open')
  const closeBtn = document.querySelectorAll('.js-modal-close')

  openBtn.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault()
      const id = el.getAttribute('data-modal-id')
      openModal(id)
    })
  })
  
  closeBtn.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault()
      const target = e.currentTarget
      closeModal(target)
    })
  })

  // phone input

  // const phone = document.querySelectorAll('input[type="tel"]')

  // if (phone) {
  //   phone.forEach(el => {
  //     Inputmask({
  //       regex: "[+\]\\d*",
  //       clearMaskOnLostFocus: false,
  //       placeholder: ''
  //     }).mask(el)
  //   })
  // }

  // clear all inputs inside form container

  function clearAllInputs (container) {
    const inputs = container.querySelectorAll('input')
    const fileName = container.querySelectorAll('.js-file-name')

    inputs.forEach(el => {
      el.classList.remove('error')
      el.value = ''
    })

    if (!fileName.length) return;

    fileName.forEach(el => {
      el.innerHTML = ''
      el.closest('.js-file').classList.remove('active')
    })
  }

  // send cv form

  const cv = document.querySelector('.js-cv')

  if (cv) {
    const openFormBtn = cv.querySelector('.js-cv-open-form')
    const closeFormBtn = cv.querySelector('.js-cv-close-form')
    const plate = cv.querySelector('.js-cv-plate')
    const form = cv.querySelector('.js-cv-form')
    const submit = cv.querySelector('.js-submit')

    openFormBtn.addEventListener('click', e => {
      e.preventDefault()
      $(plate).fadeOut(300).promise().done(() => {
        $(form).fadeIn(300)
      })
    })

    closeFormBtn.addEventListener('click', e => {
      e.preventDefault()
      $(form).fadeOut(300).promise().done(() => {
        clearAllInputs(form)
        $(plate).fadeIn(300)
      })
    })

    if (!submit) return;

    submit.addEventListener('click', e => {
      e.preventDefault()

      // run next code on ajax success

      $(form).fadeOut(300).promise().done(() => {
        clearAllInputs(form)
        $(plate).fadeIn(300)
      })
      openModal('success')
    })
  }
  
})();

// File

class File {
  constructor (el) {
    this.el = el
    this.input = this.el.querySelector('input')
    this.clearBtn = this.el.querySelector('.js-file-clear')
    this.fileName = this.el.querySelector('.js-file-name')

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
  }

  initEvents () {
    this.input.addEventListener('change', e => this.renderFileName(e))
    this.clearBtn.addEventListener('click', e => this.clear(e))
  }

  clear (e) {
    e.preventDefault()
    this.input.value = ''
    this.fileName.innerHTML = ''
    this.el.classList.remove('active')
  }

  humanFileSize (bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  renderFileName (e) {
    this.el.classList.add('active')
    const file = e.target.files[0]
    this.fileName.innerHTML = file.name + ' <span>(' + this.humanFileSize(file.size, true) + ')</span>'
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelectorAll('.js-file')

  if (el.length) {
    el.forEach(el => {
      new File(el)
    })
  }
})
